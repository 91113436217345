const trimString = {
  methods: {
    // eslint-disable-next-line no-unused-vars
    trimString(value) {
      if (value) {
        return value.trim()
      }
      else{
        return ''
      }
    }
  }
}

export default trimString;
